import i18n from '@/plugins/i18n'
export default {
    computed: {
        lang() {
            return i18n.locale
        }
    },
    methods: {
        readConfig(prop) {
            if (!this.$store.state.config) {
                return ""
            }
            if (!this.$store.state.config[prop]) {
                return ""
            }
            const l = i18n.locale
            const lFallback = i18n.fallbackLocale
            return this.$store.state.config[prop][l] || this.$store.state.config[prop][lFallback] || ""
        }
    }
} 