const axios = require('axios')

export async function trackPageview(page) {
    const response = await axios.post('/api/track/pageview', {
        page,
    })
    if (!response) {
        return { success: false }
    }
    return response.data;    
}
