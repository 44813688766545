const axios = require('axios')

export async function getAllOrders() {
    const response = await axios.get('/api/orders');
    if (!response.status === 200) {
        const data = await response.data
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
    }    
    return response.data
}

export async function getSchedule() {
    const response = await axios.get('/api/schedule');
    if (!response.status === 200) {
        const data = await response.data
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
    }    
    return response.data
}

export async function getHistory() {
    const response = await axios.get('/api/history');
    if (!response.status === 200) {
        const data = await response.data
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
    }    
    return response.data
}

export async function getCredit() {
    const response = await axios.get('/api/credit');
    if (!response.status === 200) {
        const data = await response.data
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
    }    
    return response.data
}

export async function getLoyalty() {
    const response = await axios.get('/api/loyalty');
    if (!response.status === 200) {
        const data = await response.data
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
    }    
    return response.data
}

export async function donateVoucherCheck(voucherid, vouchercodeid, orderid) {
    const response = await axios.post('/api/voucher/donate/check', {
        voucherid,
        vouchercodeid,
        orderid,
    })
    if (!response.status === 200) {
        const data = await response.data
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
    }
    return response.data;
}

export async function donateVoucherExecute(voucherid, vouchercodeid, orderid) {
    const response = await axios.post('/api/voucher/donate/execute', {
        voucherid,
        vouchercodeid,
        orderid,
    })
    if (!response.status === 200) {
        const data = await response.data
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
    }
    return response.data;
}

export async function refundVoucherCheck(voucherid, vouchercodeid, orderid) {
    const response = await axios.post('/api/voucher/refund/check', {
        voucherid,
        vouchercodeid,
        orderid,
    })
    if (!response.status === 200) {
        const data = await response.data
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
    }
    return response.data;    
}

export async function refundVoucherExecute(voucherid, vouchercodeid, orderid) {
    const response = await axios.post('/api/voucher/refund/execute', {
        voucherid,
        vouchercodeid,
        orderid,
    })
    if (!response.status === 200) {
        const data = await response.data
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
    }
    return response.data;  
}

export async function retourCheck(eventid) {
    const response = await axios.post('/api/cancel/check', {
        eventid,
    })
    if (!response) {
        return []
    }
    return response.data;
}

export async function retourExecute({eventid, type}) {
    const response = await axios.post('/api/cancel/execute', {
        eventid,
        type,
    })
    if (!response) {
        return []
    }
    return response.data;    
}

export async function getTicketsUrl(orderid, tickets) {
    const response = await axios.post('/api/tickets', {
        orderid,
        tickets,
    })
    if (!response) {
        return {}
    }    
    return response.data
}