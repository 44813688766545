import Vue from "vue"
import axios from 'axios'
import LogRocket from 'logrocket'
import store from '../store'

let instance

/** Returns the current instance of the SDK */
export const getInstance = () => instance

/** Creates an instance of the Auth SDK. If one has already been created, it returns that instance */
export const useAuth = () => {
    if (instance) return instance

    // The 'instance' is simply a Vue object
    instance = new Vue({
      data() {
        return {
          loading: true,
          isAuthenticated: false,
          user: null,
          contactinfo: null,
          error: null,
        }
      },
      methods: {
        logoutFrontend() {
          this.isAuthenticated = false
          this.user = null
          this.contactinfo = null          
        },        
        /** Logs the user out and removes their session on the authorization server */
        async logout() {
           this.isAuthenticated = false
           this.user = null
           this.contactinfo = null
           await axios.get('/auth/logout')
        }
      },
      /** Use this lifecycle method to instantiate the SDK client */
      async created() {
        try {
          // Initialize the internal authentication state
          const response = await axios('/api/config')
          this.isAuthenticated = (response && response.data && response.data.contactid) ? true : false
          this.user = (response && response.data && response.data.contactid) ? response.data.contactid : null
          this.contactinfo = (response && response.data && response.data.contactinfo) ? response.data.contactinfo : {
            lastname: "",
            middlename: "",
            firstname: "",
          }

          if (this.isAuthenticated){
            await store.commit('SET_ACCOUNTCONFIG', response.data.config);

            LogRocket.identify(this.user, {
              name: this.contactinfo.lastname,
            })
          }
        }
        catch (error) {
          this.isAuthenticated = false
          this.user = null
          this.contactinfo =  {
            lastname: "",
            middlename: "",
            firstname: "",
          }
        }

        this.loading = false
      }
    })
  
    return instance
  }
  
  // Create a simple Vue plugin to expose the wrapper object throughout the application
  export const AuthPlugin = {
    install(Vue, options) {
      Vue.prototype.$auth = useAuth(options)
    }
  }