import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'nl': {
        app: {
            portal: "Mijn Omgeving",
            logout: "Uitloggen",
        },
        tickets: {
            title: "Mijn tickets",
        },
        history: {
            title: 'Mijn Bestelhistorie',
            noTickets: "Je hebt geen tickets voor evenementen die al geweest zijn.",
            tryAgainOrContact: "Probeer het later nog een keer of neem contact met ons op via",
            or: "of",

        },
        schedule: {
            returntickets: "Retourneren",
            tickets: "Tickets",
        },
        error: {
            fetchingData: "Het is niet gelukt de gegevens op te halen.",
        }
    },
    'en': {
        app: {
            portal: "Customer Portal",
            logout: "Log out",
        },
        tickets: {
            title: "My tickets",
        },
        history: {
            title: 'Order history',
            noTickets: "No tickets for historical events found.",
            tryAgainOrContact: "Try again later or contact us via",
            or: "or",
        },
        schedule: {
            returntickets: "Return",
            tickets: "Tickets",
        },
        error: {
            fetchingData: "Fetching data failed."
        },
    }
};

const i18n = new VueI18n({
    locale: 'nl', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n;