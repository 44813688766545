import i18n from '@/plugins/i18n'

function getTitle (vm) {
    const { title } = vm.$options
    if (title) {
      return typeof title === 'function'
        ? title.call(vm)
        : title
    }
}
export default {
  created () {
    const key = getTitle(this)
    if (key) {
      document.title = i18n.t(key)
    }
  }
}