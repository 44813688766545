import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "../auth/authGuard"
import { getInstance } from "../auth/index"
import store from '../store'
import { trackPageview } from '../services/TrackService'

Vue.use(VueRouter)

const routes = [
  { 
    path: '/', 
    name: 'Home',
  },
  {
    path: '/info',
    name: 'Persoonlijke gegevens',
    // route level code-splitting
    // this generates a separate chunk (....[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Personal.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/contactinfo',
    name: 'Persoonlijke gegevens bewerken',
    // route level code-splitting
    // this generates a separate chunk (....[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Contactinfo.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/contactinfo2',
    name: 'Persoonlijke gegevens bewerken 2',
    // route level code-splitting
    // this generates a separate chunk (....[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ContactinfoDynamic.vue'),
    beforeEnter: authGuard,
  },  
  {
    path: '/optins',
    name: 'Voorkeuren bewerken',
    // route level code-splitting
    // this generates a separate chunk (....[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Optins.vue'),
    beforeEnter: authGuard,
  },    
  {
    path: '/schedule',
    name: 'Tickets',
    component: () => import('../views/Tickets.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/waitinglist',
    name: 'Wachtlijst',
    component: () => import('../views/Waitinglist.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/credit',
    name: 'Tegoeden',
    component: () => import('../views/Credit.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/loyalty',
    name: 'Voordelen',
    component: () => import('../views/Loyalty.vue'),
    beforeEnter: authGuard,
  },
  {
    path: '/login',
    name: 'Start',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/logout',
    name: 'Uitloggen',
    async beforeEnter() {
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name !== "Uitloggen" && to.name !== "Start") {
    try {
      trackPageview(to.name)
    }
    catch(err) {
      console.log(`Did not track pageview '${to.name}'`)
    }
  }

  if (to.name === "Uitloggen") {
    const authService = getInstance()
    await authService.logout()

    const returnUrl = store.state.config.returnUrl;
    store.dispatch("resetState")

    if (returnUrl) {
      location.href = returnUrl;
    }
    else {
      return '/login'
    }
    next()
  }

  // Handle default page
  if (to.path === '/') {
    if (store.state.config.startpage) {
      return next(store.state.config.startpage);
    } else {
      const waitTime = 2000;
      const timeoutPromise = new Promise(resolve => setTimeout(resolve, waitTime, '/schedule'));
      const startpagePromise = new Promise(resolve => {
        const unsubscribe = store.watch(
          (state) => state.config.startpage,
          (startpage) => {
            if (startpage) {
              unsubscribe();
              resolve(startpage);
            }
          }
        );
      });

      return next(await Promise.race([timeoutPromise, startpagePromise]));
    }
  }

  next()
})

export default router
