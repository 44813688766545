const axios = require('axios')

export async function getWaitinglist() {
    const response = await axios.get('/api/waitinglist');
    if (!response.status === 200) {
        const data = await response.data
        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
    }    
    return response.data
}

export async function deleteRequestExecute(eventid) {
    const response = await axios.post('/api/waitinglist/delete', {
        eventid,
    })
    if (!response) {
        return []
    }
    return response.data;    
}
